// import http from "@/utils/request";
import http from "@/utils/request";
export function getList(params) {
  return http({
    method: "get",
    url: "/defectiveProduct/list.nd",
    params
  });
}

// 加入购物车
export function addCart(params) {
    return http({
      method: "get",
      url: "/cart/addToCartNew.nd",
      params
    });
  }
// 导出
export function exportOrder(params) {
    return http({
      method:'post',
      url:'/defectiveProduct/export.nd',
      params,
      responseType: 'blob'
    })
}

// /defectiveProduct/update.nd?id=13964978418&audit=通过&auditRemark=驳回原因
export function updateStatus(params) {
  return http({
    method: "get",
    url: "/defective/audit.nd",
    params
  });
}